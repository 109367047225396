"use strict";
// https://www.youtube.com/watch?v=xJAfLdUgdc4&list=PLjcjAqAnHd1EIxV4FSZIiJZvsdrBc1Xho
// Terminal 
// ... npm init -y             
//         [install a bundler]
// ... npm install parcel --save-dev ... or ... npm install parcel -g ... or ... npm install --save-dev parcel
//         [takes a while]
//         [create index.html file]
//         [install threejs]
// ... npm install three
//         [create script.js file]
// ... parcel ./src/index.html --- does not work 3.17
// ... npx parcel src/index.html > Server running at http://localhost:1234 (updates automatically)
// ... ... provides a local link ... in this case - http://localhost:1234/ ... link via Chrome
// ctrl+C regains Terminal control
// ... npm install dat.gui
// to automatically update:
// ... npx parcel src/index.html > Server running at http://localhost:1234 (updates automatically)
// 
// import * as dat from 'dat.gui';
// 
import * as THREE from 'three';
// Can move axis with mouse button, zoom with mouse wheel, slide left/right with right mouse button
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import * as dat from 'dat.gui';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
// Are placed under "dist" with strange names - csan be ignored
import stars from '../img/stars.jpg'



// Creates a new glb file under "dist"
// const monkeyUrl = new URL('../assets/monkey.glb', import.meta.url);
const carUrl = new URL('../assets/Car.glb', import.meta.url);
// const carUrl = new URL('../assets/Sketchfab2.glb', import.meta.url);



const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);
const scene = new THREE.Scene();
// PerspectiveCamera( fov : Number, aspect : Number, near : Number, far : Number )
// fov — Camera frustum vertical "field of view" 
//   FOV is the part of the scene that is visible on display at any given moment.  
//   The value is in "degrees".  Humans have an almost 180-degree FOV. 
//   Since a regular computer screen doesn’t fill our vision,a smaller value is often chosen.
//   A FOV between 60 and 90 degrees is preferred.
// aspect — Camera frustum aspect ratio.
// near — Camera frustum near plane.
//   Closest that is inside FOV
// far — Camera frustum far plane.
//   Fartherist that is inside FOV
// https://www.tutorialspoint.com/threejs/threejs_perspectivecamera.htm
//
//Change field of view
const camera = new THREE.PerspectiveCamera(
  45,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
// Can move axis with mouse button, zoom with mouse wheel, slide left/right with right mouse button
const orbit = new OrbitControls(camera, renderer.domElement);
// Camera position from center of axes
camera.position.set(0, 30, 30);
camera.lookAt(0, 0, 0);
// Can move axis with mouse button, zoom with mouse wheel, slide left/right with right mouse button
orbit.update();
// Box - temporarily to show grid size
const boxGeometry = new THREE.BoxGeometry(1, 1, 1);
const boxMaterial = new THREE.MeshBasicMaterial({color: 0xFF00FF});
const box = new THREE.Mesh(boxGeometry, boxMaterial);
box.position.z = -14.5;
box.position.y = 0.5;
scene.add(box);
// Create a Plane
const planeGeometry = new THREE.PlaneGeometry(30, 30);
const planeMaterial = new THREE.MeshStandardMaterial({
  color: 0xFFFFFF,
  side: THREE.DoubleSide 
})
const plane = new THREE.Mesh(planeGeometry, planeMaterial);
scene.add(plane);
plane.rotation.x = -0.5 * Math.PI;
// size of squares, divisions, color, color
const gridHelper = new THREE.GridHelper(30, 10, 0x408090, 0x408090);
scene.add(gridHelper);
// Ambient light color
const ambientLight = new THREE.AmbientLight(0xFFFFFF);
scene.add(ambientLight);
// Background is stars
const textureLoader = new THREE.TextureLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();
scene.background = cubeTextureLoader.load([
  stars,
  stars,
  stars,
  stars,
  stars,
  stars
]);
// Display glb image
const assetLoader = new GLTFLoader();
// assetLoader.load(monkeyUrl.href, function(gltf) {
//   const model = gltf.scene;
//   scene.add(model);
//   model.position.set(-12, 4, 10);
// }, undefined, function(error) {
//   console.error(error);
// });
assetLoader.load(carUrl.href, function(glb) {
  const model = glb.scene;
  scene.add(model);
  model.position.set(0, 1, 0);
  model.scale.set(30, 30, 30);
  // model.scale.set(1, 1, 1);
}, undefined, function(error) {
  console.error(error);
});
//????????????????????
const gui = new dat.GUI();
const mousePosition = new THREE.Vector2();
window.addEventListener('mousemove', function(e) {
  mousePosition.x = (e.clientX / window.innerWidth) * 2 - 1;
  mousePosition.y = - (e.clientY / window.innerHeight) * 2 + 1;
})
function animate(time) {
  renderer.render(scene, camera);
}//animate
renderer.setAnimationLoop(animate);
window.addEventListener('resize', function() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.add.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});
